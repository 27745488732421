body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.editor-shell {
  margin: 5px 0!important;
}
.dropdown{
  z-index: 10000 !important;
}
.Modal__overlay{
  z-index: 10000 !important;
}
.toolbar{
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
}
.editor-shell{
  max-width: 100% !important;
}
.editor-shell .editor-container {
  border-bottom: 1px solid rgb(170, 170, 170);
  border-left: 1px solid rgb(170, 170, 170);
  border-right: 1px solid rgb(170, 170, 170);
}
.editor-shell .toolbar {
  overflow: auto;
  height: 40px !important;
  border-top: 1px solid rgb(170, 170, 170);
  border-left: 1px solid rgb(170, 170, 170);
  border-right: 1px solid rgb(170, 170, 170);
}
.ck.ck-content.ck-editor__editable{
  min-height: 250px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.ck-powered-by{
  display: none !important;
}
.ck.ck-sticky-panel__content{
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
:root {
  --ck-border-radius: 8px !important;
}